import Practical from '../Practical.js';
import SkillsSummarizer from "./SkillsSummarizer";
import LegacyFeature from "../Features/LegacyFeature";

class Regular {
  static efficiencyWorkersWithBuildings (app) {
    let buildings = [
      "tunnels",
      "farms",
    ];

    let increase = 1;

    for (let key in buildings) {
      let building = buildings[key];

      let count = app.state[building];
      let efficiency = app.settings.performance[building]["efficiency"] ?? 0;

      if (count > 0 && efficiency !== 0) {
        increase += (count * efficiency);
      }
    }

    const player_increase = SkillsSummarizer.getPlayerSkillParam(app, "chief", "increase") ?? 0;
    increase += player_increase;

    return Practical.number(increase, 4);
  }

  static getSearchChance(app) {
    let grades = app.settings.performance.search.chance_grades;
    let count = app.state.special;
    let chance = 0;

    for (let requirement in grades) {
      if (count >= requirement) {
        chance = grades[requirement];
      } else {
        break;
      }
    }

    return chance;
  }

  static getSearchAcceleration(app) {
    let step = app.settings.performance.search.acceleration_step;
    let count = app.state.special;

    return Practical.number(count * step, 2);
  }

  static production (app, state) {
    let production = app.settings.production;

    const has_available_limit = this.getAvailablePopulationLimit(state) > 0;

    for (let maker in production) {
      let resources = production[maker];

      for (let resource in resources) {
        let increase = 0.00;

        if (resource === "workers") {
          const player_increase = SkillsSummarizer.getPlayerSkillParam(app, "chief", "special") ?? 0;

          increase = has_available_limit > 0
            ? this.efficiency(app, resource, maker) + player_increase
            : 0;
        } else if (resource === "specialists") {
          increase = has_available_limit > 0
            ? this.efficiencySpecialistsGrowth(app)
            : 0;
        } else {
          increase = this.efficiency(app, resource, maker);
        }

        if (increase !== 0) {
          let count = app.state[resource];
          let stack = this.getStackState(app, resource) + increase;

          while (stack >= 1) {
            count++;
            stack--;

            if (resource === "specialists") {
              state["workers"]--;
            }
          }

          state[resource] = count;
          state['stacks'][resource] = stack;
        }
      }
    }

    // TODO: Optimize
    let cleaning = this.efficiencyCleaning(app);

    if (cleaning !== 0) {
      let resource = 'raw';
      let count = app.state[resource];
      let stack = this.getStackState(app, resource) + cleaning;

      while (stack >= 1) {
        count++;
        stack--;
      }

      while (stack <= -1 && count > 0) {
        count--;
        stack++;
      }

      state[resource] = count;
      state['stacks'][resource] = stack;
    }

    return state;
  }

  static efficiency (app, resource_type, resource_maker) {
    let maker_count = app.state[resource_maker];

    let resource_production = app.settings.production[resource_maker][resource_type] ?? 0;
    let efficiency = Practical.number(resource_production * maker_count, 4);

    if (resource_maker === "workers") {
      if (resource_type === "raw") {
        if (app.player.stage.code === "few-raw") {
          efficiency = Practical.number(efficiency * 0.7, 4);
        }

        efficiency *= this.efficiencyWorkersWithBuildings(app);
      }
    } else if (resource_maker === "walls") {
      if (app.player.stage.code === "few-observers") {
        efficiency = Practical.number(efficiency / 2, 4);
      }

      const player_increase = SkillsSummarizer.getPlayerSkillParam(app, "inviter", "increase");

      if (player_increase > 0) {
        efficiency += player_increase;
      }
    } else if (resource_maker === "posts") {
      if (app.player.stage.code === "few-observers" && resource_type === "observers") {
        efficiency = Practical.number(efficiency / 2, 4);
      }
    }

    if (
        (resource_maker === "posts" && resource_type === "workers") ||
        (resource_maker === "farms" && resource_type === "nectar")
    ) {
      if (app.state.storage >= 75) {
        efficiency *= (1 + app.settings.performance[resource_maker]["acceleration"]);
      }
    }

    const legacy = app.legacy.getPlayerState(app.state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_WORK) {
      efficiency *= 2;
    }

    return Practical.number(efficiency, 4);
  }

  static efficiencySpecialistsGrowth(app) {
    const growth = app.settings.performance.specialists.growth; // 0.0001
    const acceleration = app.settings.production.storage.specialists; // 0.02
    const workers = app.state.workers;
    const storage = app.state.storage;
    const player_increase = SkillsSummarizer.getPlayerSkillParam(app, "promoter", "increase") ?? 0;

    let efficiency = (storage > 0)
      ? (growth * workers) * (1 + acceleration * storage) + player_increase
      : 0;

    const legacy = app.legacy.getPlayerState(app.state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_WORK) {
      efficiency *= 2;
    }

    return Practical.number(efficiency, 4);
  }

  static efficiencyCleaning(app) {
    let efficiency = app.settings.performance.cleaning.efficiency;

    if (app.player.stage.code === "high-cleaning") {
      efficiency *= 2;
    }

    const buildings = [
        "walls",
        "tunnels",
        "storage",
        "posts",
        "farms",
        "special",
    ];

    let decrease = 0.00;

    for (let key in buildings) {
      let building = buildings[key];
      let buildings_count = app.state[building];

      if (buildings_count > 0) {
        decrease -= (buildings_count * efficiency);
      }
    }

    return Practical.number(decrease, 4);
  }

  static getCurrentCost (cost, count) {
    let increase = Math.floor(count / 25) * 0.65;
    let resource = cost * (1 + increase);

    return Practical.number(resource, 0);
  }

  static getStackState (app, type) {
    let stacks = app.state.stacks;

    return stacks.hasOwnProperty(type)
      ? stacks[type]
      : 0;
  }

  static getPopulationValue(state) {
    return Practical.number(
      (
        state.workers +
        state.specialists +
        state.posts +
        state.farms +
        state.special
      ),
      0
    );
  }

  static getPopulationLimit (state) {
    return Practical.number((state.tunnels + state.farms) * 4) + 4;
  }

  static getAvailablePopulationLimit(state) {
    let value = this.getPopulationValue(state);
    let limit = this.getPopulationLimit(state);

    return Practical.number(limit - value, 0);
  }
}

export default Regular;
