import DefaultSector from "../DefaultSector";
import Practical from "../../../Engine/Practical";

class WoodRaw extends DefaultSector {
    parameters () {
        return {
            name: 'wood',
            type: this.types.collect,
            need_clicks: 8,
            resources: {},
            result: {
                raw: 1,
            }
        }
    }

    getNeedClicks () {
        let need_clicks = super.getNeedClicks();

        const increase = this.getPlayerSkillParam("collector", "increase");

        if (increase > 0) {
            need_clicks -= need_clicks * increase;
        }

        return need_clicks;
    }

    getResult () {
        let result = super.getResult();

        const raw_chance = this.getPlayerSkillParam("collector", "chance");
        const observer_chance = this.getPlayerSkillParam("inviter", "chance");

        if (Math.random() <= raw_chance) {
            result.raw = Practical.number(result.raw) + 1;
        }

        if (Math.random() <= observer_chance) {
            result.observers = Practical.number(result.observers) + 1;
        }

        return result;
    }

    getSwitcherClass () {
        return 'raw';
    }
}

export default WoodRaw;