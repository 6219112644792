import React from 'react';
import StageSpecialTile from "./StageSpecialTile";
import LegacyFeature from "../../../Engine/Features/LegacyFeature";

class StageSpecials extends React.Component {
  render () {
    const app = this.props.app;

    const process_progress_max = app.settings.processing.ahead ?? 0;
    const process_progress_current = app.state.process.remain ?? 0;
    const process_prompt = app.prompt["gameplay-processing"] ?? "???";

    const legacy = app.legacy.getPlayerState(app.state);

    let legacyTile;

    if (legacy !== null && [LegacyFeature.STATUS_WORK, LegacyFeature.STATUS_REST].includes(legacy.status)) {
      const legacy_progress_max = app.settings.legacy.time;
      const legacy_progress_current = legacy.value;
      const legacy_prompt = app.prompt["legacy-" + legacy.status] ?? "???";

      const legacy_name = "legacy-" + legacy.status;
      const legacy_color = legacy.status === LegacyFeature.STATUS_WORK
        ? "choice"
        : "dark";

      legacyTile = <StageSpecialTile
          name={legacy_name}
          color={legacy_color}
          progress_max={legacy_progress_max}
          progress_current={legacy_progress_current}
          prompt={legacy_prompt}
      />
    }

    return (
      <div className={"stage-specials"}>
        <StageSpecialTile
          name={"process"}
          color={"dark"}
          progress_max={process_progress_max}
          progress_current={process_progress_current}
          prompt={process_prompt}
        />
        {legacyTile}
      </div>
    );
  }
}

export default StageSpecials;
