import DataManager from "./DataManager";
import Stage from "../Entities/Stage";

class StagesManager {
  static STAGE_STATUS_NULL = "null";
  static STAGE_STATUS_PROGRESS = "progress";
  static STAGE_STATUS_DISABLED = "disabled";

  static getOpenStages (level) {
    const stages = [];

    const sch_stages = DataManager.getSchema(DataManager.SCH_NAME_STAGES);

    for (let key in sch_stages) {
      const data_stage = sch_stages[key];

      if (data_stage["max_level"] < level - 1) {
        continue;
      }

      let status;

      if (data_stage["max_level"] < level) {
        status = StagesManager.STAGE_STATUS_NULL;
      } else if (data_stage["min_level"] <= level) {
        status = StagesManager.STAGE_STATUS_PROGRESS;
      } else {
        status = StagesManager.STAGE_STATUS_DISABLED;
      }

      stages.push(new Stage ({
        code: data_stage["code"],
        level: data_stage["min_level"],
        status: status,
      }));

      if (stages.length >= 4) {
        break;
      }
    }

    return stages;
  }
}

export default StagesManager;
