import React from 'react';
import ProgressBinding from "../Progress/ProgressBinding";
import {Tooltip} from "react-tooltip";
import LegacyFeature from "../../../Engine/Features/LegacyFeature";

class PlayerLegacyBoard extends React.Component {
  render () {
    const action = this.props.action;
    const player = this.props.player;
    const key = "player_legacy_choice";
    const hidden = this.props.hidden ?? false
    const level = hidden ? 10 : player.level
    const definition = LegacyFeature.getDefinition(level);
    const prompt = this.props.prompt;

    const onResult = {
      "func": action,
      "params": level
    };

    const content = <div>
        <div className={"gate-body-legacy-block"} id={key}/>
        <Tooltip className={"tip-wrapper"} anchorId={key} delayShow={500} key={key + '-prm'}>
          <div key={key + '-span-def'}>{prompt["legacy"] ?? "???"}</div>
          <span key={key + '-span-skill'}>{definition}</span>
        </Tooltip>
    </div>;

    return (hidden)
      ? <ProgressBinding
            class={"gate-body-legacy-block"}
            type={"hidden"}
            content={""}
            onResult={onResult}
            disposable={true}
            scale={20}
            key={key + "-progress"}
        />
      : <ProgressBinding
            class={"gate-body-legacy"}
            type={"wide"}
            color={"choice"}
            disposable={true}
            content={content}
            onResult={onResult}
            key={key + "-progress"}
        />
  }
}

export default PlayerLegacyBoard;
