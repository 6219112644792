import React from 'react';
import {Tooltip} from "react-tooltip";
import DataManager from "../../../Engine/Managers/DataManager";

class AchievementTile extends React.Component {
  render () {
    const code = this.props.code;

    const status_class_postfix = (this.props.is_retrieved === true)
      ? '-done'
      : '-process';

    const tip_id = "tip-achievement-" + code;
    const prompt = DataManager.getAchievementDefinition(code);

    return (
      <div className={"achievement-item"}>
        <div className={"achievement-item-image achievement-item-image-" + code + status_class_postfix} id={tip_id}/>
        <Tooltip className={"tip-wrapper"} anchorId={tip_id} delayShow={500}>
          <span>{prompt}</span>
        </Tooltip>
      </div>
    )
  }
}

export default AchievementTile;