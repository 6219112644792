import React from 'react';
import { Tooltip } from "react-tooltip";

class StageCardTile extends React.Component {
  render () {
    const size = this.props.size ?? "small";
    const stage = this.props.stage;
    const level = this.props.level;
    const level_word = "lv";
    const status = this.props.status;
    const prompt = this.props.prompt;
    const key = "stage-" + stage + "-" + size;
    const prompt_id = "tip-" + key;
    let class_name = "stage-tile-" + size;

    if (size === "medium") {
      const class_name_status = class_name + "-" + status;
      class_name += " " + class_name_status;
    }

    return (
      <div className={class_name} key={key}>
        <div className={"stage-image stage-image-" + stage} id={prompt_id} key={key + '-i'}/>
        <div className={"stage-level"} key={key + '-lvl'}>{level}<span>{level_word}</span></div>
        <div className={"stage-status stage-status-" + status} key={key + '-st'}/>
        <Tooltip className={"tip-wrapper"} anchorId={prompt_id} delayShow={500} key={key + '-prm'}>
          <span key={key + '-span'}>{prompt}</span>
        </Tooltip>
      </div>
    );
  }
}

export default StageCardTile;
