import React from 'react';
import Regular from "../../../../Engine/Processing/Regular";

class LimitResourceCounter extends React.Component {
  render () {
    const population = Regular.getPopulationValue(this.props.app.state);
    const limit = Regular.getPopulationLimit(this.props.app.state);
    const is_visible = this.props.app.state['workers'] >= 4;

    return is_visible
      ? <div className={"resource-limit"}>
            {population} / {limit}
        </div>
      : ""
  }
}

export default LimitResourceCounter;