import Cookies from 'universal-cookie';
import Stage from "../Entities/Stage";
import StageResult from "../Entities/StageResult";
import GameProgress from "../Entities/GameProgress";

class CookieManager {
    cookie_name = 'anticlicker-game-progress';

    constructor () {
        this.cookies = new Cookies();
    }

    load () {
        const data = this.cookies.get(this.cookie_name);

        let game;

        if (data !== undefined) {
            game = new GameProgress(data.uuid, data.account, data.player, data.state);
            data.player.stage = new Stage(data.player.stage ?? {});
            data.player.result = new StageResult(data.player.result ?? {});
        }

        return game;
    }

    save (game) {
        let cookie = JSON.stringify(
            game.toJson()
        );

        this.cookies.set(this.cookie_name, cookie, {expires: new Date(Date.now() + (365 * 24 * 60 * 60))})
    }

    reset () {
        this.cookies.remove(this.cookie_name);
    }
}

export default CookieManager;
