import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../Engine/Processing/Regular";
import Practical from "../../../../Engine/Practical";

class NectarResourceCounter extends ResourceCounter {
  getResource () {
    return 'nectar';
  }

  getPosition() {
    return 'right';
  }

  getIncrease () {
    const prodWorkers = Regular.efficiency(this.app, this.getResource(), 'workers');
    const prodFarms = Regular.efficiency(this.app, this.getResource(), 'farms');

    return Practical.number(prodWorkers + prodFarms, 4).toFixed(3)
  }

  getVisibleCondition() {
    return (
      this.app.state.storage >= 25
    );
  }
}

export default NectarResourceCounter;