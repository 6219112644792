import React from 'react';
import PlayerLevelTile from "./PlayerLevelTile";
import PlayerProgressTile from "./PlayerProgressTile";
import PlayerSkills from "./PlayerSkills";

class PlayerBoard extends React.Component {
  render () {
    const app = this.props.app;

    const level_value = app.player.level;
    const level_name = "leader";

    const progress_image = level_name;
    const progress_value = level_value;

    const features = app.player.features;
    const skills = app.player.skills;

    return (
      <div className={"player-board"}>
        <PlayerLevelTile
          name={level_name}
          level={level_value}
        />
        <PlayerProgressTile
          type={progress_image}
          value={progress_value}
        />
        <PlayerSkills
          features={features}
          skills={skills}
        />
      </div>
    );
  }
}

export default PlayerBoard;
