import React from 'react';

class PlayerLevelTile extends React.Component {
  render () {
    const name = this.props.name;
    const level_value = this.props.level;
    const level_word = "lv";

    return (
      <div className={"player-level"}>
        <div className={"player-level-name"}>{name}</div>
        <div className={"player-level-value"}>
          {level_value}
          <span>{level_word}</span>
        </div>
      </div>
    );
  }
}

export default PlayerLevelTile;
