import React from 'react';
import {validate} from "uuid";
import {Tooltip} from "react-tooltip";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CSSTransition} from "react-transition-group";

class PlayerProgressCode extends React.Component {
  state = {
    code: null,
    link: null,
  };

  render () {
    const uuid = this.props.uuid ?? "";
    const prompt = this.props.prompt ?? [];

    let board = "";

    if (validate(uuid)) {
        const link = window.location + uuid;
        board = <div className={"gate-body-progress-code"}>
            <CopyToClipboard text={uuid} onCopy={() => this.setState({code: true, link: false})}>
                <div className={"progress-code-content"} id={"progress-code-value-id"}>
                    Your progress code:<br/>
                    <div className={"progress-code-content-value"}>{uuid}</div>
                    <CSSTransition in={this.state.code} timeout={500} classNames={"transition-flow"}>
                        <div className={"transition-flow-appear progress-code-copy-message"}>Copied</div>
                    </CSSTransition>
                </div>
            </CopyToClipboard>
            <Tooltip className={"tip-wrapper"} anchorId={"progress-code-value-id"} delayShow={500} key={"progress-code-value-prm"}>
                <div>{prompt["player-code-copy-1"] ?? "???"}</div>
                <span>{prompt["player-code-copy-2"] ?? "???"}</span>
            </Tooltip>
            <CopyToClipboard text={link} onCopy={() => this.setState({code: false, link: true})}>
                <div className={"progress-code-copy-link"} id={"progress-code-link-id"}>
                    Copy link to your progress game
                    <CSSTransition in={this.state.link} timeout={500} classNames={"transition-flow"}>
                        <div className={"transition-flow-appear progress-code-copy-message"}>Copied</div>
                    </CSSTransition>
                </div>
            </CopyToClipboard>
            <Tooltip className={"tip-wrapper"} anchorId={"progress-code-link-id"} delayShow={500} key={"progress-code-link-prm"}>
                <div>{prompt["player-code-copy-link"] ?? "???"}</div>
                <span className={"progress-code-copy-link-prompt"}>{link}</span>
            </Tooltip>
        </div>;
    }

    return board;
  }
}

export default PlayerProgressCode;
