import React from 'react';
import { Tooltip } from "react-tooltip";
import DataManager from "../../../Engine/Managers/DataManager";

class PlayerSkillTile extends React.Component {
  render () {
    const code = this.props.code;
    const level = this.props.level;
    const prompt_id = "prompt-skill-" + this.props.code;
    const definition = DataManager.getSkillDefinition(code, level);

    return (
      <div>
        <div className={"player-skill-" + code} id={prompt_id}>
          {level}
        </div>
        <Tooltip className={"tip-wrapper"} anchorId={prompt_id} delayShow={600}>
          <span>{definition}</span>
        </Tooltip>
      </div>
    );
  }
}

export default PlayerSkillTile;
