import React from 'react';
import DataManager from "../../../Engine/Managers/DataManager";
import ProgressScale from "../Progress/ProgressScale";

class SkillCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clicks: 0
    }

    this.max_clicks = 10;
    this.is_available = props.is_available;

    this.onClick = this.onClick.bind(this);
  }

  getContent () {
    const code = this.props.code;
    const level = this.props.level;
    const layer = this.props.layer;

    const descriptions = DataManager.getDescriptions(DataManager.DSC_TYPE_SKILLS);
    const description = DataManager.getSkillDefinition(code, level);

    return (
      <div className={"skill-card skill-card-layer-" + layer}>
        <div className={"title"}>
          {descriptions[code]}
        </div>
        <div className={"level"}>
          {level}
        </div>
        <div className={"image"}>
          <div className={"skill-image-panel-" + code}/>
        </div>
        <div className={"description"}>
          {description}
        </div>
      </div>
    )
  }

  onClick () {
    if (this.is_available) {
        let clicks = this.state.clicks + 1;

        if (clicks >= this.max_clicks) {
            clicks = 0;
            this.onResult();
        }

        this.setState({
            clicks: clicks
        })
    }
  }

  onResult () {
    this.props.onSkill(this.props.code);
  }

  render () {
    let skill_block_class = "skill-block";

    if (!this.is_available) {
      skill_block_class += " skill-block-disabled";
    }

    return (
      <div className={skill_block_class} onMouseDown={this.onClick}>
        {this.getContent()}
        <ProgressScale type={"wide"} color={"choice"} max={this.max_clicks} current={this.state.clicks}/>
      </div>
    );
  }
}

export default SkillCard;
