import React from 'react';
import ProgressScale from "../Progress/ProgressScale";

class PlayerProgressTile extends React.Component {
  render () {
    const type = this.props.type;
    const value = this.props.value;

    return (
      <div className={"player-progress"}>
        <div className={"player-progress-image player-progress-image-" + type}/>
        <div className={"player-progress-value"}>
          <ProgressScale type={"small"} color={"dark"} max={20} current={value}/>
        </div>
      </div>
    );
  }
}

export default PlayerProgressTile;
