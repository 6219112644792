import React from 'react';
import SkillsChoiceBoard from "../Components/Skills/SkillsChoiceBoard";
import StageExitBoard from "../Components/Stages/StageExitBoard";
import AchievementsBoard from "../Components/Achievements/AchievementsBoard";
import {Tooltip} from "react-tooltip";

class StageEndDesk extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const onSkill = this.props.onSkill;
    const onExit = this.props.onExit;

    const message1 = 'You found queen for your anthill and your job was finished here.';
    const message2 = 'Your level upped to ' + player.result.growth + "!";

    const SkillsOrExitBoard = player.result.growth > 0
      ? <SkillsChoiceBoard player={player} onSkill={onSkill}/>
      : <StageExitBoard onExit={onExit} prompt={app.prompt['gameplay-finish']}/>;

    return (
      <div className={"stage-finish-desk"}>
        <div className={"head-line"}/>
        <div className={"queen-found"}/>
        <div className={"messages-board"}>
            <div className={"message-plank"}>{message1}</div>
            <div className={"message-plank"}>{message2}</div>
            <div className={"messages-level-up"} id={"tip-level-up"}>
                <div className={"image"}></div>
                <div className={"value-" + player.result.growth}></div>
            </div>
            <Tooltip className={"tip-wrapper"} anchorId={"tip-level-up"} delayShow={500}>
                <span>{app.prompt["gameplay-skills"]}</span>
            </Tooltip>
        </div>
        <AchievementsBoard app={app}/>
        <div className={"arrow-line"}/>
        {SkillsOrExitBoard}
        <div className={"ground-line"}/>
        <div className={"ground-fill"}/>
      </div>
    );
  }
}

export default StageEndDesk;
