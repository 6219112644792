import React from 'react';
import {CSSTransition} from "react-transition-group";

class ResourceFlow extends React.Component {
  render () {
    let result = [];

    let resources = this.props.resources ?? {};
    let transition = this.props.transition ?? null;

    for (let key in resources) {
      let count = resources[key];

      let sign = "";

      if (transition !== null) {
        if (count < 0) {
          count *= -1;
          sign = "-";
        } else {
          sign = "+";
        }
      } else {
        sign = "";
      }

      if (transition !== null) {
        result.push(
          <div className={"resource-flow-transition"} key={key}>
            <div className={"resource-flow-sign"}>{sign}</div>
            <div className={"resource-flow-count"}>{count}</div>
            <div className={"resource-flow-image"}>
              <img src={"/images/icons/" + key + ".png"} alt={key} title={key}/>
            </div>
          </div>
        );
      } else {
        const resource_class = count > 9
          ? 'resource-flow-requirement-long'
          : 'resource-flow-requirement-short';

        result.push(
          <div className={"resource-flow-static"} key={key}>
            <div className={"resource-flow-requirement " + resource_class} key={key}>
              <div className={"resource-flow-image"}>
                <img src={"/images/icons/" + key + ".png"} alt={key} title={key}/>
              </div>
              <div className={"resource-flow-count"}>{count}</div>
            </div>
          </div>
        );
      }
    }

    if (transition !== null) {
      const timeout = 400 + (200 * result.length);

      result = <CSSTransition in={transition} timeout={timeout} classNames={"transition-flow"}>
        <div>
          {result}
        </div>
      </CSSTransition>
    }

    return result;
  }
}

export default ResourceFlow;
