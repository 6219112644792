import React from 'react';

class CopyrightLine extends React.Component {
  render () {
    return (
      <span className={"copyright"}>
        &copy; 2024, Gunzhin
      </span>
    );
  }
}

export default CopyrightLine;
