import Stage from "./Stage";
import StageResult from "./StageResult";

class Player {
    static STATUS_GATE = "gate";
    static STATUS_PLAY_STAGE = "play_stage";
    static STATUS_FINISH_STAGE = "finish_stage";

    constructor(data = {}) {
        this._level = data.level ?? 0;
        this._status = data.status ?? Player.STATUS_GATE;
        this._skills = data.skills ?? {};
        this._features = data.features ?? {};
        this._stage = data.stage
            ? new Stage(data.stage)
            : {};
        this._achievements = data.achievements ?? [];
        this._result = data.result
            ? new StageResult(data.result)
            : {};
    }

    levelUp () {
        this._level++;
    }

    resetLevel () {
        this._level = 0;
    }

    choiceSkill (code) {
        this._skills[code] = this._skills.hasOwnProperty(code)
          ? ++this._skills[code]
          : 1;
    }

    resetSkills () {
        this._skills = {};
    }

    takeFeature (code, value) {
        let current = this._features[code] ?? 0;
        this._features[code] = current + value;
    }

    setStatus (status) {
        const available_statuses = [
            Player.STATUS_GATE,
            Player.STATUS_PLAY_STAGE,
            Player.STATUS_FINISH_STAGE
        ];

        if (available_statuses.includes(status)) {
            this._status = status;
        }
    }

    setStage (stage) {
        this._stage = (stage instanceof Stage)
            ? stage
            : null;
    }

    setResult (result) {
        this._result = (result instanceof StageResult)
            ? result
            : null;
    }

    toJson () {
        return {
            level: this._level,
            status: this._status,
            skills: this._skills,
            features: this._features,
            stage: (this._stage instanceof Stage)
              ? this._stage.toJson()
              : null,
            achievements: this._achievements,
            result: (this._result instanceof StageResult)
                ? this._result.toJson()
                : null,
        }
    }

    get level () {
        return this._level;
    }

    get status () {
        return this._status;
    }

    get skills () {
        return this._skills;
    }

    get features () {
        return this._features;
    }

    get stage () {
        return this._stage;
    }

    get achievements () {
        return this._achievements;
    }

    get result () {
        return this._result;
    }
}

export default Player;
