import DefaultSector from "../DefaultSector";
import Regular from "../../../Engine/Processing/Regular";
import Practical from "../../../Engine/Practical";

class Recruitment extends DefaultSector {
    parameters () {
        return {
            name: 'recruitment',
            type: this.types.procreate,
            need_clicks: 16,
            resources: {
                procreate: "workers"
            },
            preview: {
                walls: 1,
            },
            requirements: {
                observers: 20,
            },
            result: {
                observers: -20,
                workers: 1,
            }
        }
    }

    getNeedClicks () {
        let need_clicks = super.getNeedClicks();

        const increase = this.getPlayerSkillParam("recruiter", "increase");

        if (increase > 0) {
            need_clicks -= Practical.number(need_clicks * increase, 0);
        }

        return need_clicks;
    }

    getRequirements () {
        let app = this.props.app;
        let available_limit = Regular.getAvailablePopulationLimit(app.state);

        let requirements = super.getRequirements();

        const player_requirement = this.getPlayerSkillParam("recruiter", "requirement");

        if (player_requirement > 0) {
            requirements["observers"] -= player_requirement;
        }

        if (available_limit <= 0) {
            requirements["limit"] = Practical.number((available_limit * -1) + 1, 0);
        }

        return requirements;
    }
}

export default Recruitment;