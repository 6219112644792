import React from 'react';
import SkillCard from "./SkillCard";
import SkillsSummarizer from "../../../Engine/Processing/SkillsSummarizer";

class SkillsChoiceBoard extends React.Component {
  render () {
    const player = this.props.player;
    let render = [];

    if (player.result.growth > 0) {
      const items = SkillsSummarizer.getSelectableSkills(player);

      for (let n in items) {
        const item = items[n];

        render.push(
            <SkillCard key={n} code={item.code} level={item.level} layer={item.layer} is_available={item.is_available} onSkill={this.props.onSkill}/>
        );
      }

      const empty_cards_count = 4 - items.length;

      if (empty_cards_count > 0) {
        for (let n = 0; n < empty_cards_count; n++) {
          render.push(
            <div className={"skill-block"}>
              <div className={"skill-card skill-card-empty"}/>
            </div>
          );
        }
      }
    }

    return (
      <div className={"skill-choice"}>
        {render}
      </div>
    );
  }
}

export default SkillsChoiceBoard;
