import React from 'react';
import Regular from "../../../../Engine/Processing/Regular";
import Practical from "../../../../Engine/Practical";
import {Tooltip} from "react-tooltip";

class QueenProgressCounter extends React.Component {
  render () {
    const chance = Practical.number(Regular.getSearchChance(this.props.app) * 100, 0);
    const acceleration = Practical.number(Regular.getSearchAcceleration(this.props.app) * 100, 0);

    const prompt_text = this.props.app.prompt["storage-effect-4"];
    const is_visible = this.props.app.state['storage'] >= 100;

    return is_visible
      ? <div className={"queen-progress"} id={"storage-effect-id"}>
          <div className={"queen-progress-value"}>
            {acceleration}
            <span className={"queen-progress-percent"}>%</span>
          </div>
          <div className={"queen-progress-value"}>
            {chance}
            <span className={"queen-progress-percent"}>%</span>
          </div>
          <Tooltip className={"tip-wrapper"} anchorId={"storage-effect-id"} delayShow={500} key={"storage-effect-prm"}>
            <span>{prompt_text}</span>
          </Tooltip>
        </div>
      : ""
  }
}

export default QueenProgressCounter;