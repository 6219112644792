import React from 'react';
import CopyrightLine from "../Components/General/CopyrightLine";
import PatreonLink from "../Components/General/PatreonLink";
import DataManager from "../../Engine/Managers/DataManager";

class StageGroundDesk extends React.Component {
  render () {
    return (
      <div className={"stage-ground-desk"}>
        <div className={"ground-info-line"}>
          <CopyrightLine/>
          <div className={"dash"}>|</div>
          <PatreonLink type={"sml"}/>
          <div className={"dash"}>|</div>
          <div className={"ver"}>v{ DataManager.getVersion() }</div>
        </div>
        <div className={"ground-filler-line"}/>
      </div>
    );
  }
}

export default StageGroundDesk;
