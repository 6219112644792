import GameProgress from "../Entities/GameProgress";
import DataManager from "./DataManager";

class ServerApiManager {
    getUrl(path) {
        return DataManager.getHomepage() + path;
    }

    async getGameProgress (uuid) {
        let result;

        await fetch(this.getUrl('/api/game_progress/' + uuid), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            },
        })
            .then(response => response.json())
            .then(json => {result = GameProgress.init(json)})
            .catch(err => console.error(err));

        return await result;
    }

    saveGameProgress (game) {
        fetch(this.getUrl('/api/game_progress/' + game.uuid), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(game.toJson())
        });
    }

    async initGameProgress (game) {
        return await fetch(this.getUrl('/api/game_progress/'), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(game.toJson())
        });
    }
}

export default ServerApiManager;
