import React from 'react';
import Regular from "../../../../Engine/Processing/Regular";
import Practical from "../../../../Engine/Practical";

class ObserversResourceCounter extends React.Component {
  render () {
    const count = this.props.app.state['observers'];
    const walls_increase = Regular.efficiency(this.props.app, 'observers', 'walls');
    const posts_decrease = Regular.efficiency(this.props.app, 'observers', 'posts');
    const increase = Practical.number(walls_increase + posts_decrease).toFixed(2);
    const is_visible = this.props.app.state['walls'] > 0;

    return is_visible
      ? <div className={"resource-observers"}>
          <div className={"resource-observers-count"}>
            {count}
          </div>
          <div className={"resource-observers-increase"}>
            {increase}
            <span className={"resource-increase-measure"}>/s</span>
          </div>
        </div>
      : ""
  }
}

export default ObserversResourceCounter;