import React from 'react';
import {Tooltip} from "react-tooltip";

class StorageProgressCounter extends React.Component {
  render () {
    const count = this.props.app.state['storage'];
    const uppers = Math.floor(count / 25);
    const percentage = (count % 25) * 4;
    const is_visible = this.props.app.state['storage'] >= 1 && this.props.app.state['storage'] < 100;
    const prompt = this.props.app.prompt;

    let uppers_sector = [];
    let prompt_text = prompt["storage-effect-0"];

    for (let n = 1; n <= 3; n++) {
      let upper_type;

      if (n <= uppers) {
        upper_type = 'fill';
        prompt_text = prompt["storage-effect-" + uppers];
      } else {
        upper_type = 'empty';
      }

      uppers_sector.push(
        <div className={"storage-progress-upper-" + upper_type}/>
      )
    }

    const style = {
      width: percentage + "%",
    };

    return is_visible
      ? <div className={"storage-progress"} id={"storage-effect-id"}>
          {uppers_sector}
          <div className={"storage-progress-percentage"}>
            <div className={"storage-progress-percentage-active"} style={style}/>
          </div>
          <Tooltip className={"tip-wrapper"} anchorId={"storage-effect-id"} delayShow={500} key={"storage-effect-prm"}>
            <span>{prompt_text}</span>
          </Tooltip>
        </div>
      : ""
  }
}

export default StorageProgressCounter;