import Player from "./Player";

class GameProgress {
    constructor(uuid, account, player, state) {
        this._uuid = uuid;
        this._account = account;
        this._player = new Player(player);
        this._state = state;
    }

    toJson () {
        return {
            uuid: this._uuid,
            account: this._account,
            player: this._player.toJson(),
            state: this._state,
        }
    }

    static init (json) {
        return new this(json.uuid, json.account, json.player, json.state);
    }

    get uuid () {
        return this._uuid;
    }

    get account () {
        return this._account;
    }

    get player () {
        return this._player;
    }

    get state () {
        return this._state;
    }
}

export default GameProgress;
