import React from 'react';
import ProgressScale from "../Progress/ProgressScale";
import {Tooltip} from "react-tooltip";

class StageExitBoard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clicks: 0
    }

    this.max_clicks = 10;
    this.prompt = props.prompt;

    this.onClick = this.onClick.bind(this);
  }

  onClick () {
    let clicks = this.state.clicks + 1;

    if (clicks >= this.max_clicks) {
      this.props.onExit();
    } else {
      this.setState({
        clicks: clicks
      })
    }
  }

  render () {
    return (
      <div className={"exit-board"} onMouseDown={this.onClick}>
        <div className={"exit-sector"} id={"tip-exit-stage"}/>
        <Tooltip className={"tip-wrapper"} anchorId={"tip-exit-stage"} delayShow={500}>
          <span>{this.prompt}</span>
        </Tooltip>
        <ProgressScale type={"wide"} color={"choice"} max={this.max_clicks} current={this.state.clicks}/>
      </div>
    )
  }
}

export default StageExitBoard;