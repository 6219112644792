import React from 'react';

class PatreonLink extends React.Component {
  render () {
    const type = this.props.type ?? "std";

    return (
      <a className={"patreon-link-" + type}
         href={"https://www.patreon.com/gunzhin"}
         target="_blank"
         rel="noopener noreferrer"
      >

      </a>
    );
  }
}

export default PatreonLink;
