import React from 'react';
import StageCardTile from "./StageCardTile";
import ProgressBinding from "../Progress/ProgressBinding";
import StagesManager from "../../../Engine/Managers/StagesManager";

class StageChoiceBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;

    const stages = StagesManager.getOpenStages(player.level);

    const tiles = [];

    for (let key in stages) {
      const stage = stages[key].toJson();
      const prompt = app.prompt["stage-" + stage.code] ?? "???";

      let tile = (
        <div className={"stage-choice-item"} key={stage.code + '_card'}>
          <StageCardTile
            size={"medium"}
            stage={stage.code}
            level={stage.level}
            status={stage.status}
            prompt={prompt}
          />
        </div>
      );

      const onResult = {
        "func": app.onStage,
        "params": stage["code"]
      }

      tile = (
        <ProgressBinding
          class={"stage-progress-bindings stage-choice-item-order" + key}
          type={"wide"}
          color={"choice"}
          content={tile}
          onResult={onResult}
          key={stage.code + '_progress'}
          disabled={stage["status"] === "disabled"}
        />
      )

      tiles.push(tile);
    }

    return tiles;
  }
}

export default StageChoiceBoard;