import React from 'react';
import ProgressScale from '../Components/Progress/ProgressScale.js';
import Regular from "../../Engine/Processing/Regular";
import Practical from "../../Engine/Practical";
import SkillsSummarizer from "../../Engine/Processing/SkillsSummarizer";
import ResourceFlow from "../Components/Resources/ResourceFlow";
import { Tooltip } from "react-tooltip";

class DefaultSector extends React.Component {
  types = {
    collect: "collect",
    procreate: "procreate",
    building: "building",
    upgrade: "upgrade",
  };

  constructor(props) {
    super(props);

    this.state = {
      clicks: 0,
      result: {},
      show_result: false,
      show_prompt: false,
      timer: null,
    };

    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  parameters () {
    return {
      name: "default",
      type: null,
      resources: {},
      need_clicks: 5,
      preview: {},
      requirements: {},
      result: {},
    }
  }

  getName () {
    return this.parameters().name;
  }

  getType () {
    return this.parameters().type;
  }

  getNeedClicks () {
    return Number(this.parameters().need_clicks);
  }

  getResource (name) {
    let resources = this.parameters().resources;

    return resources.hasOwnProperty(name)
      ? resources[name]
      : null;
  }

  getResourceCount (name) {
    let resource = this.getResource(name);
    let state = this.props.app.state;

    return state[resource] ?? 0;
  }

  needClicks () {
      let need_clicks = this.getNeedClicks();

      const app = this.props.app;

      if (this.getType() === this.types.building) {
        const player_increase = this.getPlayerSkillParam("builder", "increase");

        if (player_increase > 0) {
          need_clicks -= need_clicks * player_increase;
        }

        let specialists_performance = app.settings.performance.specialists.efficiency ?? 0;
        let specialists_increase = Practical.number(app.state.specialists) * specialists_performance;

        need_clicks /= (1 + specialists_increase);
      }

      if (this.getType() === this.types.upgrade) {
        const player_increase = this.getPlayerSkillParam("upgrader", "increase");

        if (player_increase > 0) {
          need_clicks -= need_clicks * player_increase;
        }
      }

      return Practical.number(need_clicks, 0);
  }

  getPreview () {
    return this.parameters().preview;
  }

  getRequirements () {
    return this.prepareParameters("requirements");
  }

  getResult () {
    let result = {};
    let type = this.getType();

    if ([this.types.building, this.types.upgrade].includes(type)) {
      result = this.getRequirements();

      for (let key in result) {
        result[key] *= -1;
      }

      let resource = this.getResource(type);

      result[resource] = 1;

      if (type === this.types.building) {
        const player_chance = this.getPlayerSkillParam("builder", "chance");
        const player_chance_special = this.getPlayerSkillParam("builder", "special");

        if (Math.random() <= player_chance_special) {
          result.raw = Practical.number(result.raw * 0.50, 0);
        } else if (Math.random() <= player_chance) {
          result.raw = Practical.number(result.raw * 0.75, 0);
        }
      } else if (type === this.types.upgrade) {
        const player_chance = this.getPlayerSkillParam("upgrader", "chance");
        const player_chance_special = this.getPlayerSkillParam("upgrader", "special");

        if (Math.random() <= player_chance_special) {
          result.nectar += 2;
        } else if (Math.random() <= player_chance) {
          result.nectar += 1;
        }
      }
    } else {
      result = this.prepareParameters("result");

      const player_requirement = this.getPlayerSkillParam("recruiter", "requirement");
      const player_training_chance = this.getPlayerSkillParam("trainer", "chance");

      if (
          player_requirement > 0 &&
          result.hasOwnProperty("observers")
      ) {
        result["observers"] += player_requirement;
      }

      if (
          player_training_chance > 0 &&
          result.hasOwnProperty("specialists") &&
          Math.random() <= player_training_chance
      ) {
        result["specialists"] += 1;
      }
    }

    return result;
  }

  getClosureBuildingCost (building, price) {
    return function (app) {
      return Regular.getCurrentCost(price, app.state[building]);
    }
  }

  prepareParameters (field) {
    let result = {};
    let parameters = this.parameters()[field];

    if (typeof parameters === "function") {
      result = parameters;
    } else {
      for (let key in parameters) {
        let value = parameters[key];

        if (typeof value === "function") {
          value = value(this.props.app);
        }

        result[key] = value;
      }
    }

    return result;
  }

  canClicking () {
    return (
      this.getType() !== this.types.upgrade ||
      this.getResourceCount(this.types.building) > this.getResourceCount(this.types.upgrade)
    )
      ? Practical.requirementsAreMet(this.getRequirements(), this.props.app.state)
      : false;
  }

  onClick () {
    clearTimeout(this.state.timer);

    let state = {};

    if (this.canClicking()) {
      let stack_clicks = this.state.clicks + 1;
      let need_clicks = this.needClicks();
      let need_result = stack_clicks >= need_clicks;

      let show_result = (need_clicks - stack_clicks) !== 1;

      if (need_result === true) {
        const result = this.getResult();

        this.props.onResult(result);

        state.clicks = 0;
        state.result = result;
        state.show_result = true;
      } else {
        state.clicks = stack_clicks;
        state.show_result = show_result;
      }
    }

    state.show_prompt = false;
    state.timer = null;

    this.setState(state);
  };

  onMouseEnter () {
    this.setState({
      timer: setTimeout(() => this.setState({show_prompt: true}), 1200)
    })
  };

  onMouseLeave () {
    clearTimeout(this.state.timer);
    this.setState({show_prompt: false});
  };

  getSwitcherClass () {
    return 'active';
  }

  getPlayerSkillParam (type, param) {
    return SkillsSummarizer.getPlayerSkillParam(this.props.app, type, param);
  }

  render () {
    const app = this.props.app;

    let state = app.state;
    let name = this.getName();
    let type = this.getType();
    let preview = this.getPreview();
    let requirements = this.getRequirements();
    let classNameSector = "sector sector-" + name;
    let classNameSectorSpecific = "sector-specific sector-specific-" + name;
    let sector_counters;

    let is_available = true;

    for (let preview_key in preview) {
      if (state[preview_key] < preview[preview_key]) {
        is_available = false;
        break;
      }
    }

    let render = null;

    if (is_available) {
      if (! this.canClicking()) {
        classNameSector += " sector-disable";
        classNameSectorSpecific += " sector-specific-disable";
      }

      if ([this.types.building, this.types.upgrade].includes(type)) {
        let buildings_count = this.getResourceCount(this.types.building);
        let upgrades_count = this.getResourceCount(this.types.upgrade);

        let sector_counter_builds_class = (upgrades_count > 0)
          ? "sector-counter-builds"
          : "sector-counter-builds-single";

        let sector_counter_upgrades_class = "sector-counter-upgrades";

        let key = {
          'bg': name + this.types.building + 'group',
          'ug': name + this.types.upgrade + 'group',
          'bv': name + this.types.building + 'value',
          'uv': name + this.types.upgrade + 'value',
          'bl': name + this.types.building + 'line',
          'ul': name + this.types.upgrade + 'line',
        };

        let sector_counter_builds_selected_class = (type === this.types.building)
          ? 'sector-counter-line-selected'
          : 'sector-counter-line-empty';

        let sector_counter_builds = (
          <div key={key.bg} className={'sector-counter-block'}>
            <div key={key.bl} className={sector_counter_builds_selected_class}/>
            <div key={key.bv} className={sector_counter_builds_class}>
              {buildings_count}
            </div>
          </div>
        );

        let counters = [];
        counters.push(sector_counter_builds);

        if (upgrades_count) {
          let sector_counter_upgrades_selected_class = (type === this.types.upgrade)
            ? 'sector-counter-line-selected'
            : 'sector-counter-line-empty';

          let sector_counter_upgrades = (
            <div key={key.ug} className={'sector-counter-block'}>
              <div key={key.ul} className={sector_counter_upgrades_selected_class}/>
              <div key={key.uv} className={sector_counter_upgrades_class}>
                {upgrades_count}
              </div>
            </div>
          );

          counters.push(sector_counter_upgrades);
        }

        sector_counters = (
          <div className={"sector-counters"}>{counters}</div>
        );
      }

      const prompt_key = name + '-prompt';
      const description = app.prompt[name] ?? '???';

      let sector_specific = (
        <div>
          <div className={classNameSectorSpecific}
               onMouseDown={this.onClick}
               onMouseEnter={this.onMouseEnter}
               onMouseLeave={this.onMouseLeave}
               id={prompt_key}
          />
          <Tooltip className={"tip-wrapper"} anchorId={prompt_key} delayShow={600} isOpen={this.state.show_prompt === true}>
            <span>{description}</span>
          </Tooltip>
        </div>
      );

      let sector_progress = (
        <ProgressScale type={(this.getName() === "wood") ? "wide" : "regular"} max={this.needClicks()} current={this.state.clicks}/>
      );

      let resources_requirements = Object.keys(requirements).length > 0
        ? <ResourceFlow resources={requirements}/>
        : null;

      let resource_flow_values = this.state.result;

      if ([this.types.building, this.types.upgrade, this.types.procreate].includes(type)) {
        const resource_name = this.getResource(type);

        if (resource_flow_values.hasOwnProperty(resource_name)) {
          delete resource_flow_values[resource_name];
        }
      }

      let resources_changes = <ResourceFlow resources={resource_flow_values} transition={this.state.show_result}/>

      render = (
        <div className={classNameSector}>
          {sector_counters}
          {sector_specific}
          {sector_progress}
          {resources_requirements}
          {resources_changes}
        </div>
      );
    }

    return render;
  }
}

export default DefaultSector;
