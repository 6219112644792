import React from 'react';
import { Tooltip } from "react-tooltip";

class ResourceCounter extends React.Component {
  constructor(props) {
    super(props);

    this.app = props.app;
  }

  getResource () {
    return this.props.resource ?? '';
  }

  getCount () {
    return this.app.state[this.getResource()];
  }

  getIncrease () {
    return Number(0).toFixed(2);
  }

  getVisibleCondition () {
    return true;
  }

  getPosition () {
    return 'left';
  }

  render () {
    const resource = this.getResource();
    const count = this.getCount();
    const increase = this.getIncrease();
    const is_visible = this.getVisibleCondition();
    const position = this.getPosition();
    const prompt_key = 'resource-counter-block-' + resource;
    const prompt_content = this.app.prompt[resource] ?? '???';

    const position_class = "resource-position-" + position;

    return is_visible
      ? <div>
          <div className={"resources-block"} id={prompt_key}>
            <div className={"resource-icon " + position_class}>
              <img src={"/images/icons/" + resource + "-large.png"} alt={resource} title={resource}/>
            </div>
            <div className={"resource-count " + position_class}>
              {count}
            </div>
            <div className={"resource-increase " + position_class}>
              {increase}
              <span className={"resource-increase-measure"}>/s</span>
            </div>
          </div>
          <Tooltip className={"tip-wrapper"} anchorId={prompt_key} delayShow={1200}>
            <span>{prompt_content}</span>
          </Tooltip>
        </div>
      : <div className={"resources-block"}>
          <div className={"resource-icon-hidden " + position_class}/>
          <div className={"resource-count-hidden " + position_class}/>
          <div className={"resource-increase-hidden " + position_class}/>
        </div>
  }
}

export default ResourceCounter;