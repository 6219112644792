import sch_general from "../../Data/schemes/general.json";
import sch_state from "../../Data/schemes/state.json";
import sch_stages from "../../Data/schemes/stages.json";
import sch_skills from "../../Data/schemes/skills.json";
import sch_notifications from "../../Data/schemes/notifications.json";
import sch_achievements from "../../Data/schemes/achievements.json";
import dsc_general from "../../Data/descriptions/general.json";
import dsc_prompt from "../../Data/descriptions/prompt.json";
import dsc_story from "../../Data/descriptions/story.json";
import dsc_skills from "../../Data/descriptions/skills.json";
import dsc_notifications from "../../Data/descriptions/notifications.json";
import dsc_achievements from "../../Data/descriptions/achievements.json";
import package_data from '../../../package.json';
import Practical from "../Practical";
import React from "react";

class DataManager {
  static DSC_TYPE_GENERAL = "general";
  static DSC_TYPE_ACHIEVEMENTS = "achievements";
  static DSC_TYPE_NOTIFICATIONS = "notifications";
  static DSC_TYPE_SKILLS = "skills";
  static DSC_TYPE_PROMPT = "prompt";
  static DSC_TYPE_STORY = "story";

  static SCH_NAME_GENERAL = "general";
  static SCH_NAME_ACHIEVEMENTS = "achievements";
  static SCH_NAME_NOTIFICATIONS = "notifications";
  static SCH_NAME_SKILLS = "skills";
  static SCH_NAME_STATE = "state";
  static SCH_NAME_STAGES = "stages";

  static getDescriptions (type) {
    let source;

    switch (type) {
      case this.DSC_TYPE_GENERAL:
        source = dsc_general;
        break;
      case this.DSC_TYPE_ACHIEVEMENTS:
        source = dsc_achievements;
        break;
      case this.DSC_TYPE_NOTIFICATIONS:
        source = dsc_notifications;
        break;
      case this.DSC_TYPE_PROMPT:
        source = dsc_prompt;
        break;
      case this.DSC_TYPE_SKILLS:
        source = dsc_skills;
        break;
      case this.DSC_TYPE_STORY:
        source = dsc_story;
        break;
      default:
        source = null;
        break;
    }

    return source;
  }

  static getSchema (name) {
    let schema;

    switch (name) {
      case this.SCH_NAME_GENERAL:
        schema = sch_general;
        break;
      case this.SCH_NAME_ACHIEVEMENTS:
        schema = sch_achievements;
        break;
      case this.SCH_NAME_NOTIFICATIONS:
        schema = sch_notifications;
        break;
      case this.SCH_NAME_STATE:
        schema = sch_state;
        break;
      case this.SCH_NAME_SKILLS:
        schema = sch_skills;
        break;
      case this.SCH_NAME_STAGES:
        schema = sch_stages;
        break;
      default:
        schema = null;
        break;
    }

    return schema;
  }

  static getDescriptionValue(type, code, replace = {}) {
    const descriptions = DataManager.getDescriptions(type);

    let result = [];
    let descriptionList = descriptions[code];

    if (!Array.isArray(descriptionList)) {
      descriptionList = [descriptionList];
    }

    for (let key in descriptionList) {
      let description = descriptionList[key];

      const parts = description.split("%%");

      let count = 0;
      for (let key in parts) {
        let part = parts[key];

        for (let word in replace) {
          if (part.startsWith(word)) {
            part = part.replace(word, replace[word]);
            break;
          }
        }

        if (part.endsWith(":PERCENT")) {
          part = part.replace(":PERCENT", "");
          part = Practical.number(part * 100) + "%";
        }

        if (count++ % 2) {
          part = <span className={"bold"}>{part}</span>;
        }

        parts[key] = part;
      }

      result.push(parts);
    }

    return result;
  }

  static getPrompts() {
    const descriptions = DataManager.getDescriptions("prompt");

    let result = {};

    for (let key in descriptions) {
      result[key] = DataManager.getDescriptionValue(
          DataManager.DSC_TYPE_PROMPT,
          key
      );
    }

    return result;
  }

  static getSkillDefinition (code, level) {
    const skills = DataManager.getSchema(DataManager.SCH_NAME_SKILLS);

    return DataManager.getDescriptionValue(
        DataManager.DSC_TYPE_SKILLS,
        code + "-description",
        skills[code]["values"][level]
    );
  }

  static getAchievementDefinition (code) {
    const schema = DataManager.getSchema(DataManager.SCH_NAME_ACHIEVEMENTS);
    const requirements = schema[code]["requirements"];

    let replace = {};

    for (let key in requirements) {
      const state = requirements[key]["state"];

      replace = {...replace, ...state};
    }

    return DataManager.getDescriptionValue(
        DataManager.DSC_TYPE_ACHIEVEMENTS,
        code + "-description",
        replace
    );
  }

  static getActualLayer(player) {
    const general = DataManager.getSchema(DataManager.SCH_NAME_GENERAL);

    let actual;

    for (let layer in general.layers) {
      const level = general.layers[layer];

      if (player.level >= level) {
        actual = layer;
      }
    }

    return Practical.number(actual, 0);
  }

  static getHomepage() {
    return package_data.homepage ?? '';
  }

  static getVersion() {
    return package_data.version ?? '';
  }
}

export default DataManager;
