import React from 'react';

class TitleLineTile extends React.Component {
  render () {
    return (
      <div className={"title"}/>
    );
  }
}

export default TitleLineTile;
