import React from 'react';
import PlayerSkillTile from "./PlayerSkillTile";
import PlayerFeatureTile from "./PlayerFeatureTile";

class PlayerSkills extends React.Component {
  render () {
    const features = this.props.features;
    const skills = this.props.skills;
    let skills_panel = [];

    for (let code in features) {
      const level = features[code];

      skills_panel.push(
        <PlayerFeatureTile
          code={code}
          level={level}
        />
      );
    }

    for (let code in skills) {
      const level = skills[code];

      skills_panel.push(
        <PlayerSkillTile
          code={code}
          level={level}
        />
      );
    }

    return (
      <div className={"player-skills"}>
        {skills_panel}
      </div>
    );
  }
}

export default PlayerSkills;
