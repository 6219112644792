import StagesManager from "./Managers/StagesManager";
import Player from "./Entities/Player";
import Stage from "./Entities/Stage";
import StageResult from "./Entities/StageResult";
import AchievementsWarden from "./AchievementsWarden";

class PlayerAction {
    constructor(player) {
        this.player = player;
    }

    enterStage (stage_code) {
        const stages = StagesManager.getOpenStages(this.player.level)

        let stage = null;

        for (let key in stages) {
            const current = stages[key];

            if (current.code === stage_code) {
                stage = current;
                break;
            }
        }

        if (stage instanceof Stage) {
            this.player.setStatus(Player.STATUS_PLAY_STAGE);
            this.player.setStage(stage);
        }
    }

    finishStage (state) {
        this.player.setStatus(Player.STATUS_FINISH_STAGE);

        let growth = 0;
        let has_progress;

        if (this.player.stage.status === Stage.STATUS_PROGRESS) {
            growth++;
            has_progress = true;
        } else {
            has_progress = false;
        }

        const achievement = AchievementsWarden.calculateRetrieved(this.player, state);

        if (achievement !== null) {
            this.player.achievements.push(achievement);

            if (this.player.achievements.length % 3 === 0) {
                growth++;
            }
        }

        this.player.setResult(
            new StageResult({
                "growth": growth,
                "progress": has_progress,
                "achievement": achievement,
            })
        );
    }

    exitStage () {
        this.player.setStatus(Player.STATUS_GATE);
        this.player.setResult(null);
    }
}

export default PlayerAction;
