import React from 'react';

class MessageTile extends React.Component {
  render () {
    const text = this.props.text;

    return (
      <div className={"message-tile"}>
        {text}
      </div>
    );
  }
}

export default MessageTile;
