class Process {
  
  static refresh (state, remain) {
    state.process = {
      status: true,
      remain: remain,
    };

    return state;
  }
  
  static decrease (state) {
    let remain = state.process.remain;
    let status = state.process.status;

    if (status === true && --remain <= 0) {
      status = false;
    }

    state['process'] = {
      status: status,
      remain: remain,
    };

    return state;
  }
}
  
export default Process;
