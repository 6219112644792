import DefaultSector from "../DefaultSector";

class Tunnel extends DefaultSector {
    parameters () {
        return {
            name: "tunnel",
            type: this.types.building,
            resources: {
                building: "tunnels",
                upgrade: "farms",
            },
            need_clicks: 18,
            preview: {
                workers: 4,
            },
            requirements: {
                raw: this.getClosureBuildingCost("tunnels", 12),
            },
        }
    }
}

export default Tunnel;