import React from 'react';
import Regular from "./Engine/Processing/Regular";
import Process from "./Engine/Processing/Process";
import ProgressManager from "./Engine/Managers/ProgressManager";
import StagePlayDesk from "./Display/Desks/StagePlayDesk";
import Notificator from "./Engine/Notificator";
import StageStatusDesk from "./Display/Desks/StageStatusDesk";
import GateDesk from "./Display/Desks/GateDesk";
import StageEndDesk from "./Display/Desks/StageEndDesk";
import DataManager from "./Engine/Managers/DataManager";
import Player from "./Engine/Entities/Player";
import StageGroundDesk from "./Display/Desks/StageGroundDesk";
import PlayerAction from "./Engine/PlayerAction";
import 'react-tooltip/dist/react-tooltip.css';
import LegacyFeature from "./Engine/Features/LegacyFeature";
import {validate} from "uuid";
import GameProgress from "./Engine/Entities/GameProgress";

class AntiClicker extends React.Component {
  constructor(props) {
    super(props);

    this.settings = DataManager.getSchema(DataManager.SCH_NAME_GENERAL);
    this.prompt = DataManager.getPrompts();

    this.progressManager = new ProgressManager();

    let save = this.progressManager.load();

    if (save !== null) {
      this.uuid = save.uuid;
      this.player = new Player(save.player);

      this.state = (save.state && save.state.process)
          ? save.state
          : DataManager.getSchema(DataManager.SCH_NAME_STATE);
    } else {
      this.uuid = null;
      this.player = new Player();
      this.state = DataManager.getSchema(DataManager.SCH_NAME_STATE);
    }

    this.action = new PlayerAction(this.player);
    this.legacy = new LegacyFeature(this.player);

    this.onResult = this.onResult.bind(this);
    this.onSkill = this.onSkill.bind(this);
    this.onStage = this.onStage.bind(this);
    this.onLegacy = this.onLegacy.bind(this);
    this.onExit = this.onExit.bind(this);

    this.counter();
  }

  async saveGame(state, flow = false) {
    let game;

    if (validate(this.uuid)) {
      this.progressManager.save(
          new GameProgress(this.uuid, [], this.player, state),
          flow
      )
    } else {
      game = await this.progressManager.init(
          new GameProgress(this.uuid, [], this.player, state)
      )
      this.uuid = game.uuid;
    }

    this.setState(state);
  }

  onResult (data) {
    let state = this.state;

    const changes = (typeof data === "function")
        ? data(state)
        : data;

    for (let key in changes) {
      if (state.hasOwnProperty(key)) {
        state[key] = state[key] + changes[key];
      }
    }

    if (state.queen >= 1 ) {
      this.action.finishStage(state);
      state = Process.refresh(state, 0);
    } else {
      state = Process.refresh(state, this.settings.processing.ahead);
    }

    state = this.legacy.playProgress(state);

    this.saveGame(state, true);
  };

  onSkill (code) {
    this.player.choiceSkill(code);
    this.player.levelUp();
    this.player.result.spent();

    this.saveGame({});
  }

  onStage (code) {
    this.action.enterStage(code);

    const state = DataManager.getSchema(DataManager.SCH_NAME_STATE);

    this.saveGame(state);
  }

  onLegacy (level) {
    this.player.takeFeature('legacy', level);
    this.player.resetLevel();
    this.player.resetSkills();

    this.saveGame({});
  }

  onExit () {
    this.action.exitStage();

    this.saveGame({});
  }

  counter () {
    let counters = function (app) {
      let state = app.state;

      if (state.process.status === true) {
        state = Regular.production(app, state);
        state = Process.decrease(state);

        state = app.legacy.playWork(state);
        state = app.legacy.playRest(state);

        app.setState(state);
      }

      Notificator.syncMessages(app);
    };

    setInterval(counters, 1000, this);
  }

  render () {
    const desks = [];
    const player_status = this.player.status;

    switch (player_status) {
      case Player.STATUS_GATE:
        desks.push(<GateDesk app={this} onStage={this.onStage} key={"gate"} uuid={this.uuid}/>);
        break;

      case Player.STATUS_PLAY_STAGE:
        desks.push(<StageStatusDesk app={this}/>);
        desks.push(<StagePlayDesk app={this}/>);
        desks.push(<StageGroundDesk app={this}/>);
        break;

      case Player.STATUS_FINISH_STAGE:
        desks.push(<StageStatusDesk app={this}/>);
        desks.push(<StageEndDesk app={this} onSkill={this.onSkill} onExit={this.onExit}/>);
        desks.push(<StageGroundDesk app={this}/>);
        break;

      default:
        break;
    }

    let window_class = player_status === Player.STATUS_GATE
        ? "window-gate"
        : "window-stage";

    return (
        <div className={'window ' + window_class}>
          {desks}
        </div>
    );
  }
}

export default AntiClicker;
