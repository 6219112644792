import Practical from "../Practical";
import DataManager from "../Managers/DataManager";

class LegacyFeature {
  static STATUS_PROGRESS = "progress";
  static STATUS_WORK = "work";
  static STATUS_REST = "rest";

  constructor (player) {
    this.player = player;
  }

  getProgressStep () {
    const features = this.player.features;
    const level = features.hasOwnProperty("legacy")
        ? Practical.number(features["legacy"], 0)
        : 0;

    return level > 0
      ? Practical.number((level / 10), 1)
      : 0;
  }

  getPlayerState (state) {
    const step = this.getProgressStep();

    let legacy = null;

    if (step > 0) {
      if (!state.hasOwnProperty("legacy")) {
        state["legacy"] = {};
      }

      legacy = {
        "status": state["legacy"]["status"] ?? LegacyFeature.STATUS_PROGRESS,
        "value": state["legacy"]["value"] ?? step,
      }
    }

    return legacy;
  }

  playProgress (state) {
    let legacy = this.getPlayerState(state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_PROGRESS) {
      if ((Math.random() * 100) <= legacy.value) {
        legacy.status = LegacyFeature.STATUS_WORK;
        legacy.value = 5;
      } else {
        legacy.value += this.getProgressStep();
      }

      state = Practical.mergeState(state, {"legacy": legacy});
    }

    return state;
  }

  playWork (state) {
    let legacy = this.getPlayerState(state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_WORK) {
      legacy.value--;

      if (legacy.value <= 0) {
        legacy.status = LegacyFeature.STATUS_REST;
        legacy.value = 6;
      }

      state = Practical.mergeState(state, {"legacy": legacy});
    }

    return state;
  }

  playRest (state) {
    let legacy = this.getPlayerState(state);

    if (legacy !== null && legacy.status === LegacyFeature.STATUS_REST) {
      legacy.value--;

      if (legacy.value <= 0) {
        legacy.status = LegacyFeature.STATUS_PROGRESS;
        legacy.value = this.getProgressStep();
      }

      state = Practical.mergeState(state, {"legacy": legacy});
    }

    return state;
  }

  static getDefinition (level) {
    return DataManager.getDescriptionValue(
        DataManager.DSC_TYPE_SKILLS,
        "legacy-description",
        {
          "chance": Practical.number(level / 1000, 3)
        }
    );
  }
}

export default LegacyFeature;
