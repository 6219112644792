import DefaultSector from "../DefaultSector";
import Regular from "../../../Engine/Processing/Regular";
import Practical from "../../../Engine/Practical";

class Training extends DefaultSector {
    parameters () {
        return {
            name: 'training',
            type: this.types.procreate,
            need_clicks: 16,
            resources: {
                procreate: "specialists"
            },
            preview: {
                storage: 50,
            },
            requirements: {
                nectar: 3,
            },
            result: {
                workers: -1,
                nectar: -3,
                specialists: 1,
            }
        }
    }

    getNeedClicks () {
        let need_clicks = super.getNeedClicks();

        const increase = this.getPlayerSkillParam("trainer", "increase");

        if (increase > 0) {
            need_clicks -= Practical.number(need_clicks * increase, 0);
        }

        return need_clicks;
    }

    getRequirements () {
        let app = this.props.app;
        let available_limit = Regular.getAvailablePopulationLimit(app.state);

        let requirements = super.getRequirements();

        if (available_limit <= 0) {
            requirements["limit"] = Practical.number((available_limit * -1) + 1, 0);
        }

        return requirements;
    }
}

export default Training;