import React from 'react';
import ProgressScale from "../Progress/ProgressScale";
import {Tooltip} from "react-tooltip";

class StageSpecialTile extends React.Component {
  render () {
    const name = this.props.name;
    const color = this.props.color ?? "dark";
    const progress_max = this.props.progress_max ?? 0;
    const progress_current = this.props.progress_current ?? 0;
    const prompt = this.props.prompt ?? 0;
    const prompt_id = "processing-tile-prompt-" + name;

    return (
      <div className={"stage-special-tile"} id={prompt_id}>
        <div className={"stage-special-image-" + name}/>
          <div className={"process-progress"}>
            <ProgressScale max={progress_max} current={progress_current} type={"mini"} color={color}/>
          </div>
          <Tooltip className={"tip-wrapper"} anchorId={prompt_id} delayShow={500}>
            <span>{prompt}</span>
          </Tooltip>
      </div>
    );
  }
}

export default StageSpecialTile;
