import React from 'react';
import {Tooltip} from "react-tooltip";
import AchievementsWarden from "../../../Engine/AchievementsWarden";
import AchievementTile from "./AchievementTile";
import DataManager from "../../../Engine/Managers/DataManager";

class AchievementsBoard extends React.Component {
  render () {
    const app = this.props.app;
    const player = app.player;
    const achievements = AchievementsWarden.composeActual(player);

    const prompt = app.prompt["gameplay-achievements"] ?? "???";

    let items = [];
    let status = 'done';

    for (let code in achievements) {
        const is_retrieved = player.achievements.includes(code);

        items.push(
            <AchievementTile code={code} is_retrieved={is_retrieved}/>
        );

        if (status === "done" && is_retrieved === false) {
            status = "process";
        }
    }

    const achievement = player.result.achievement;
    let message;

    if (achievement) {
        const name = DataManager.getDescriptionValue(DataManager.DSC_TYPE_ACHIEVEMENTS, achievement);
        message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned", {"NAME": name});
    } else if (status === "done") {
        message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned-all");
    } else {
        message = DataManager.getDescriptionValue(DataManager.DSC_TYPE_GENERAL, "achievement-earned-none");
    }

    const prompt_id = "tip-achievements-status";

    return (
      <div className={"achievements-board"}>
        <div className={"achievements-images"}>{items}</div>
        <div className={"achievements-message"}>{message}</div>
        <div className={"achievements-status-" + status} id={prompt_id}/>
        <Tooltip className={"tip-wrapper"} anchorId={prompt_id} delayShow={500}>
          <span>{prompt}</span>
        </Tooltip>
      </div>
    )
  }
}

export default AchievementsBoard;