import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../Engine/Processing/Regular";
import Practical from "../../../../Engine/Practical";

class RawResourceCounter extends ResourceCounter {
  getResource () {
    return 'raw';
  }

  getIncrease () {
    const value = (
        Regular.efficiency(this.app, this.getResource(), 'workers') +
        Regular.efficiencyCleaning(this.app)
    ).toFixed(3);

    return value >= 10
      ? Practical.number(value, 2).toFixed(2)
      : Practical.number(value, 3).toFixed(3);
  }

  getVisibleCondition() {
    return (
      this.app.state.raw > 0 ||
      this.app.state.walls > 0
    );
  }
}

export default RawResourceCounter;