import Practical from "./Practical";
import DataManager from "./Managers/DataManager";

class Notificator {
  static LIST_MAX_COUNT = 3;

  static getStateMessagesStack (state) {
    return state.messages.stack;
  }

  static getStateMessagesHasNew (state) {
    return state.messages.has_new;
  }

  static getDefinitions (state) {
    const notifications = DataManager.getSchema(DataManager.SCH_NAME_NOTIFICATIONS);

    let list = [];
    const stack = this.getStateMessagesStack(state);

    for (let number in notifications) {
      const notification = notifications[number];
      const definition = notification["definition"];

      if (! stack.includes(definition)) {
        continue;
      }

      list.push(definition);

      if (list.length >= this.LIST_MAX_COUNT) {
        break;
      }
    }

    return list;
  }

  static getCurrentMessages (state) {
    const definitions = this.getDefinitions(state);
    const descriptions = DataManager.getDescriptions(DataManager.DSC_TYPE_NOTIFICATIONS);

    let list = [];

    for (let key in definitions) {
      let definition = definitions[key];

      list.push(descriptions[definition]);
    }

    return list;
  }

  static getActualDefinitions (state) {
    const notifications = DataManager.getSchema(DataManager.SCH_NAME_NOTIFICATIONS);
    const currentDefinitions = this.getDefinitions(state);

    let newDefinitions = [];
    let canRecord = false;

    for (let number in notifications) {
      const notification = notifications[number];
      const definition = notification["definition"];

      if (
        currentDefinitions.length <= 0 ||
        currentDefinitions.includes(definition)
      ) {
        canRecord = true;
      }

      if (currentDefinitions.includes(definition)) {
        continue;
      }

      if (canRecord) {
        const requirements = notification["requirements"];

        if (! Practical.requirementsAreMet(requirements, state)) {
          continue;
        }

        newDefinitions.push(notification["definition"]);

        if (newDefinitions.length >= this.LIST_MAX_COUNT) {
          break
        }
      }
    }

    let definitions = [...currentDefinitions, ...newDefinitions];

    if (definitions.length > this.LIST_MAX_COUNT) {
      definitions = definitions.splice(definitions.length - this.LIST_MAX_COUNT, this.LIST_MAX_COUNT);
    }

    return definitions;
  }

  static syncMessages (app) {
    const state = app.state;
    const messages = state.messages;

    const stack = this.getStateMessagesStack(state);
    const has_new = this.getStateMessagesHasNew(state);
    const actual = Notificator.getActualDefinitions(state);

    if (String(stack) !== String(actual)) {
      messages.stack = actual;
      messages.has_new = true;
    } else if (has_new) {
      messages.has_new = false;
    }

    app.setState({
      messages: messages,
    });
  }
}

export default Notificator;
