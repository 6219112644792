import DefaultSector from "../DefaultSector";
import Practical from "../../../Engine/Practical";

class WoodNectar extends DefaultSector {
    parameters () {
        return {
            name: 'wood',
            type: this.types.collect,
            need_clicks: 15,
            resources: {},
            preview: {
                storage: 25,
            },
            result: {
                nectar: 1,
            }
        }
    }

    getNeedClicks () {
        let need_clicks = super.getNeedClicks();

        const increase = this.getPlayerSkillParam("seeker", "increase");

        if (increase > 0) {
            need_clicks -= need_clicks * increase;
        }

        return need_clicks;
    }

    getResult () {
        let result = super.getResult();

        const nectar_chance = this.getPlayerSkillParam("seeker", "chance");
        const specialist_chance = this.getPlayerSkillParam("promoter", "chance");

        if (nectar_chance > 0 && Math.random() <= nectar_chance) {
            result.nectar = Practical.number(result.nectar) + 1;
        }

        if (specialist_chance > 0 && Math.random() <= specialist_chance) {
            result.specialists = 1;
        }

        return result;
    }

    getSwitcherClass () {
        return 'nectar';
    }
}

export default WoodNectar;