import DataManager from "./Managers/DataManager";
import Practical from "./Practical";
import Regular from "./Processing/Regular";

class AchievementsWarden {
  static calculateRetrieved(player, state) {
    const layer = DataManager.getActualLayer(player);

    const achievements = Practical.filterList(
      DataManager.getSchema(DataManager.SCH_NAME_ACHIEVEMENTS),
      (item, key) => item["layer"] === layer && !player.achievements.includes(key)
    );

    let current_achievement = null;

    for (let code in achievements) {
      const achievement = achievements[code];

      for (let key in achievement["requirements"]) {
        const requirements = achievement["requirements"][key];

        const rule = requirements["rule"];
        const r_state = requirements["state"];

        if (
            rule === "gte" &&
            Practical.requirementsAreMet(r_state, state)
        ) {
            current_achievement = code;
        } else if (
            rule === "population" &&
            Regular.getPopulationValue(state) >= r_state["population"]
        ) {
            current_achievement = code;
        }
      }
    }

    return current_achievement;
  }

  static composeActual(player) {
    const layer = DataManager.getActualLayer(player);

    return Practical.filterList(
        DataManager.getSchema(DataManager.SCH_NAME_ACHIEVEMENTS),
        item => item["layer"] === layer
    );
  }
}

export default AchievementsWarden;
