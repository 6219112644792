import React from 'react';
import StageCardTile from "./StageCardTile";
import StageSpecials from "./StageSpecials";

class StageBoard extends React.Component {
  render () {
    const app = this.props.app;
    const stage = app.player.stage;
    const prompt = app.prompt["stage-" + stage.code] ?? "???";

    return (
      <div>
        <StageCardTile
          size={"small"}
          stage={stage["code"]}
          level={stage["level"]}
          status={stage["status"]}
          prompt={prompt}
        />
        <StageSpecials app={app}/>
      </div>
    )
  }
}

export default StageBoard;