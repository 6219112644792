import React from 'react';
import Tunnel from "../Sectors/Builds/Tunnel";
import Farm from "../Sectors/Upgrades/Farm";
import Post from "../Sectors/Upgrades/Post";
import Storage from "../Sectors/Builds/Storage";
import WoodRaw from "../Sectors/Wood/WoodRaw";
import WoodNectar from "../Sectors/Wood/WoodNectar";
import WoodSearch from "../Sectors/Wood/WoodSearch";
import Recruitment from "../Sectors/Work/Recruitment";
import Training from "../Sectors/Work/Training";
import Wall from "../Sectors/Builds/Wall";
import Special from "../Sectors/Upgrades/Special";
import SectorController from "../Sectors/SectorController";
import RawResourceCounter from "../Components/Resources/ResourceCounters/RawResourceCounter";
import NectarResourceCounter from "../Components/Resources/ResourceCounters/NectarResourceCounter";
import WorkersResourceCounter from "../Components/Resources/ResourceCounters/WorkersResourceCounter";
import SpecialistsResourceCounter from "../Components/Resources/ResourceCounters/SpecialistsResourceCounter";
import ObserversResourceCounter from "../Components/Resources/ResourceCounters/ObserversResourceCounter";
import LimitResourceCounter from "../Components/Resources/ResourceCounters/LimitResourceCounter";
import StorageProgressCounter from "../Components/Resources/ResourceCounters/StorageProgressCounter";
import QueenProgressCounter from "../Components/Resources/ResourceCounters/QueenProgressCounter";

class StagePlayDesk extends React.Component {
  render () {
    return (
      <div className="playground-wrapper">
        <div className={"head-zone"}/>
        <div className={"work-zone"}>
          <div className={"work-zone-left-sector"}>
            <SectorController app={this.props.app} sectors={[Recruitment]}/>
          </div>
          <div className={"work-zone-resource-left"}>
            <WorkersResourceCounter app={this.props.app}/>
          </div>
          <div className={"work-zone-center-sector"}>
            <SectorController app={this.props.app} sectors={[WoodRaw, WoodNectar, WoodSearch]}/>
          </div>
          <div className={"work-zone-resource-middle-left"}>
            <RawResourceCounter app={this.props.app}/>
          </div>
          <div className={"work-zone-resource-middle-right"}>
            <NectarResourceCounter app={this.props.app}/>
          </div>
          <div className={"work-zone-right-sector"}>
            <SectorController app={this.props.app} sectors={[Training]}/>
          </div>
          <div className={"work-zone-resource-right"}>
            <SpecialistsResourceCounter app={this.props.app}/>
          </div>
        </div>
        <div className={"build-zone"}>
          <div className={"build-zone-left-sector"}>
            <SectorController app={this.props.app} sectors={[Wall, Post]}/>
          </div>
          <div className={"build-zone-resource-left"}>
            <ObserversResourceCounter app={this.props.app}/>
          </div>
          <div className={"build-zone-center-sector"}>
            <SectorController app={this.props.app} sectors={[Tunnel, Farm]}/>
          </div>
          <div className={"build-zone-resource-middle"}>
            <LimitResourceCounter app={this.props.app}/>
          </div>
          <div className={"build-zone-right-sector"}>
            <SectorController app={this.props.app} sectors={[Storage, Special]}/>
          </div>
          <div className={"build-zone-resource-right"}>
            <StorageProgressCounter app={this.props.app}/>
            <QueenProgressCounter app={this.props.app}/>
          </div>
        </div>
      </div>
    );
  }
}

export default StagePlayDesk;
