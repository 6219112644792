class StageResult {
    constructor(data = {}) {
        this._growth = data.growth ?? 0;
        this._progress = data.progress ?? false;
        this._achievement = data.achievement ?? null;
    }

    spent () {
        this._growth--;
    }

    toJson () {
        return {
            growth: this._growth,
            progress: this._progress,
            achievement: this._achievement,
        }
    }

    get growth () {
        return this._growth;
    }

    get progress () {
        return this._progress;
    }

    get achievement () {
        return this._achievement;
    }
}

export default StageResult;
