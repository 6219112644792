import ResourceCounter from "./ResourceCounter";
import Regular from "../../../../Engine/Processing/Regular";

class SpecialistsResourceCounter extends ResourceCounter {
  getResource () {
    return 'specialists';
  }

  getPosition() {
    return 'right';
  }

  getIncrease () {
    return Regular.efficiencySpecialistsGrowth(this.app).toFixed(3);
  }

  getVisibleCondition() {
    return (
      this.app.state.specialists > 0 ||
      this.app.state.storage > 0
    );
  }
}

export default SpecialistsResourceCounter;