class Stage {
    static STATUS_NULL = "null";
    static STATUS_PROGRESS = "progress";
    static STATUS_DISABLED = "disabled";

    constructor(data = {}) {
        this._code = data.code ?? null;
        this._level = data.level ?? 0;
        this._status = data.status ?? Stage.STATUS_DISABLED;
    }

    toJson () {
        return {
            code: this._code,
            level: this._level,
            status: this._status,
        }
    }

    get code () {
        return this._code;
    }

    get level () {
        return this._level;
    }

    get status () {
        return this._status;
    }
}

export default Stage;
