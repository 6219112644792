import full_skills from "../../Data/schemes/skills.json";
import DataManager from "../Managers/DataManager";
import Practical from "../Practical";

class SkillsSummarizer {
  static getParams (code, level) {
    if (full_skills.hasOwnProperty(code)) {
      const values = full_skills[code]["values"];

      if (values.hasOwnProperty(level)) {
        return values[level];
      }
    }

    return {};
  }

  static getPlayerSkillParam (app, type, param) {
    let value = null;

    const skills = app.player.skills;

    if (skills.hasOwnProperty(type)) {
      const level = skills[type];
      const params = SkillsSummarizer.getParams(type, level);

      if (params.hasOwnProperty(param)) {
        value = params[param];
      }
    }

    return value;
  }

  static getSelectableSkills(player) {
    let first_skills = [];
    let next_skills = [];

    const full_skills = DataManager.getSchema(DataManager.SCH_NAME_SKILLS);
    const config = DataManager.getSchema(DataManager.SCH_NAME_GENERAL);
    const filled_layers = SkillsSummarizer.playerLayersSkillsFilledByLevels(player);

    for (let key in full_skills) {
      const skill = full_skills[key];
      const code = skill["code"];
      const layer = skill["layer"];

      const player_skill_level = (player.skills.hasOwnProperty(code))
        ? Practical.number(player.skills[code], 0)
        : 0;

      if (
          player_skill_level === 3 ||
          config["layers"][skill["layer"]] > player.level ||
          (
              player_skill_level === 0 &&
              filled_layers[skill["layer"]]
          )
      ) {
        continue;
      }

      const requirements = skill["require"] ?? [];

      let is_visible = true;

      if (requirements.length > 0) {
        for (let r_key in requirements) {
          const requirement_skill = requirements[r_key];

          if (
            !player.skills.hasOwnProperty(requirement_skill) ||
            player.skills[requirement_skill] !== 3
          ) {
            is_visible = false;
            break;
          }
        }
      }

      if (!is_visible) {
        continue;
      }

      const is_available = (
        player_skill_level === 0 ||
        (player_skill_level === 1 && player.level >= 2) ||
        (player_skill_level === 2 && player.level >= 4)
      );

      const selectable_skill_level = player_skill_level + 1;

      const skill_item = {
        code: code,
        level: selectable_skill_level,
        layer: layer,
        is_available: is_available,
      };

      if (selectable_skill_level <= 1) {
        first_skills.push(skill_item);
      } else {
        next_skills.push(skill_item);
      }
    }

    return [...next_skills, ...first_skills];
  }

  static playerLayersSkillsFilledByLevels(player) {
    let result = {};
    const config = DataManager.getSchema(DataManager.SCH_NAME_GENERAL);

    const all_skills = {...full_skills};

    for (let layer in config["layers"]) {
      const layer_level = config["layers"][layer];
      const layer_level_skills = Practical.filterList(all_skills, skill => parseInt(skill["layer"]) === parseInt(layer));

      let filled = 0;

      for (let skill in player.skills) {
        if (layer_level_skills.hasOwnProperty(skill)) {
          filled++;
        }
      }

      if (player.level >= layer_level + 5) {
        filled++;
      }

      result[layer] = (filled >= 3);
    }

    return result;
  }
}

export default SkillsSummarizer;
