import React from 'react';
import { Tooltip } from "react-tooltip";
import LegacyFeature from "../../../Engine/Features/LegacyFeature";

class PlayerFeatureTile extends React.Component {
  render () {
    const code = this.props.code;
    const level = this.props.level;
    const prompt_id = "prompt-feature-" + this.props.code;
    const prompt = LegacyFeature.getDefinition(level);

    return (
      <div>
        <div className={"player-feature-" + code} id={prompt_id}>
          {level}
        </div>
        <Tooltip className={"tip-wrapper"} anchorId={prompt_id} delayShow={600}>
          <span>{prompt}</span>
        </Tooltip>
      </div>
    );
  }
}

export default PlayerFeatureTile;
