import React from 'react';
import PlayerBoard from "../Components/Player/PlayerBoard";
import DataManager from "../../Engine/Managers/DataManager";
import StageChoiceBoard from "../Components/Stages/StageChoiceBoard";
import PatreonLink from "../Components/General/PatreonLink";
import CopyrightLine from "../Components/General/CopyrightLine";
import PlayerLegacyBoard from "../Components/Player/PlayerLegacyBoard";
import PlayerProgressCode from "../Components/Player/PlayerProgressCode";

class GateDesk extends React.Component {
  render () {
    let app = this.props.app;

    const general_messages = DataManager.getDescriptions(DataManager.DSC_TYPE_GENERAL);
    const welcome_messages = general_messages["gate-welcome"];
    const stages_title_message = general_messages["gate-choice-title"];
    const prompt = app.prompt;

    const story_messages = app.uuid !== null
      ? DataManager.getDescriptionValue(DataManager.DSC_TYPE_STORY, "1")
      : DataManager.getDescriptionValue(DataManager.DSC_TYPE_STORY, "0");

    const version = DataManager.getVersion();

    const legacy_board = app.player.level >= 10
      ? <PlayerLegacyBoard player={app.player} action={app.onLegacy} prompt={prompt}/>
      : <PlayerLegacyBoard player={app.player} action={app.onLegacy} prompt={prompt} hidden={true}/>;

    let welcome_message = welcome_messages.map((value, key) => <div className={"gate-paragraph"} key={"welcome_message_" + key}>{value}</div>);
    let story_message = story_messages.map((value, key) => <div className={"gate-paragraph"} key={"story_message_" + key}>{value}</div>);

    const player_progress = <PlayerProgressCode uuid={this.props.uuid ?? null} prompt={prompt}/>;

    return (
      <div className={"gate"}>
        <div className={"gate-header"}>
          <div className={"gate-header-logo"}/>
        </div>
        <div className={"gate-body"}>
          <div className={"gate-body-player"}>
            <PlayerBoard app={app}/>
          </div>
          <div className={"gate-body-story"}>
            {story_message}
            {player_progress}
          </div>
          {legacy_board}
          <div className={"gate-body-stages"}>
            <div className={"gate-body-stages-title"}>
              {stages_title_message}
            </div>
            <div className={"gate-body-stages-list"}>
              <StageChoiceBoard app={app} level={app.player.level}/>
            </div>
          </div>
        </div>
        <div className={"gate-welcome"}>
          {welcome_message}
          <div className={"gate-patreon"}>
            <PatreonLink/>
          </div>
        </div>
        <div className={"gate-ground"}>
          <CopyrightLine/> | Anticlicker v{ version }
        </div>
      </div>
    );
  }
}

export default GateDesk;
