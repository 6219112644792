import DefaultSector from "../DefaultSector";
import Regular from "../../../Engine/Processing/Regular";
import Practical from "../../../Engine/Practical";

class WoodSearch extends DefaultSector {
    parameters () {
        return {
            name: 'wood',
            type: this.types.collect,
            need_clicks: 80,
            resources: {},
            preview: {
                storage: 100,
            },
            requirements: {
                nectar: 15,
            },
            result: {},
        }
    }

    getNeedClicks () {
        return Practical.number(100 / (1 + Regular.getSearchAcceleration(this.props.app)));
    }

    getResult () {
        let app = this.props.app;
        let chance = Regular.getSearchChance(app);
        let point = Math.random()
        let queen = (chance >= point);

        let result = {
            nectar: -15
        };

        if (queen > 0) {
            result.queen = queen;
        }

        return result;
    }

    getSwitcherClass () {
        return 'queen';
    }
}

export default WoodSearch;