import React from 'react';
import Practical from "../../../Engine/Practical";

class ProgressScale extends React.Component {
  getType () {
    return this.props.type ?? "regular";
  }

  getColor () {
    return this.props.color ?? "default";
  }

  render () {
    let type = this.getType();
    let color = this.getColor();
    let percentage = Practical.percentage(this.props.max, this.props.current);

    const style = {
      width: percentage + "%",
    };

    return (
      <div className={"progress-wrapper-" + type}>
        <div className={"progress-item"}>
          <div className={"progress-empty progress-empty-color-" + color}/>
          <div className={"progress-filled progress-filled-color-" + color} style={style}/>
          <div className={"progress-border"}/>
        </div>
      </div>
    );
  }
}

export default ProgressScale;