import React from 'react';

class DisableSector extends React.Component {
  render () {
    let name = this.props.name;

    return (
      <div className={"sector"}>
        <div className={"sector-specific sector-specific-" + name + "-hidden"}/>
      </div>
    )
  }
}

export default DisableSector;
