import DefaultSector from "../DefaultSector";

class Wall extends DefaultSector {
    parameters () {
        return {
            name: "wall",
            type: this.types.building,
            resources: {
                building: "walls",
                upgrade: "posts",
            },
            need_clicks: 12,
            requirements: {
                raw: this.getClosureBuildingCost("walls", 6),
            },
        }
    }
}

export default Wall;